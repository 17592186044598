<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'theClient',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/client/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '客户端ID',
          type: 'input',
          key: 'clientId'
        },
        {
          name: '客户端密钥',
          type: 'input',
          key: 'clientSecret'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'let-90',
          isId: true,
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'clientId',
          title: '客户端ID',
          type: 'let-100',
          sorter: (a, b) => a.clientId.length - b.clientId.length
        },
        {
          dataIndex: 'clientSecret',
          title: '客户端密钥',
          type: 'let-100',
          sorter: (a, b) => a.clientSecret.length - b.clientSecret.length
        },
        {
          dataIndex: 'authorities',
          title: '权限列表',
          type: 'let-100',
          isId: true,
          sorter: (a, b) => a.authorities.length - b.authorities.length
        },
        {
          dataIndex: 'accessTokenValidity',
          title: '请求令牌有效时间',
          type: 'let-100',
          sorter: (a, b) => a.accessTokenValidity - b.accessTokenValidity
        },
        {
          dataIndex: 'authorizedGrantTypes',
          title: '授权方式',
          type: 'let-100',
          isId: true,
          sorter: (a, b) => a.authorizedGrantTypes.length - b.authorizedGrantTypes.length
        },
        {
          dataIndex: 'additionalInformation',
          title: '扩展信息',
          type: 'let-100',
          isId: true,
          sorter: (a, b) => a.additionalInformation.length - b.additionalInformation.length
        },
        {
          dataIndex: 'autoapprove',
          title: '是否自动放行',
          type: 'let-100',
          isId: true,
          sorter: (a, b) => a.autoapprove.length - b.autoapprove.length
        },
        {
          dataIndex: 'refreshTokenValidity',
          title: '刷新令牌有效时间',
          type: 'let-100',
          sorter: (a, b) => a.refreshTokenValidity - b.refreshTokenValidity
        },
        {
          dataIndex: 'resourceIds',
          title: '资源id列表',
          type: 'let-100',
          isId: true,
          sorter: (a, b) => a.resourceIds.length - b.resourceIds.length
        },
        {
          dataIndex: 'scope',
          title: '作用域',
          type: 'let-100',
          sorter: (a, b) => a.scope.length - b.scope.length
        },
        {
          dataIndex: 'webServerRedirectUri',
          title: '回调地址',
          type: 'let-100',
          isId: true,
          sorter: (a, b) => a.webServerRedirectUri.length - b.webServerRedirectUri.length
        },
        {
          dataIndex: 'delFlag',
          title: '状态',
          width: '60px',
          type: 'badge',
          isId: true,
          onExport: records => {
            return ['正常', '已删除'][records]
          },
          filters: [
            {
              text: '正常',
              value: '0'
            },
            {
              text: '已删除',
              value: '1'
            }
          ],
          onFilter: (value, record) => record.delFlag == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '正常' : '失效',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => this.$router.push(`/system/theClientDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/client/view/${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/system/theClientDetail')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
